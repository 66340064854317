<template>
  <div class="rule">
    <h2>【回收详细说明】</h2>
    <p>1、部分补给箱支持回收玩法，将会在补给箱列表中明确标识，如因用户不喜欢所购得的商品，可以在拆封前进行回收，回收由用户主动提出，平台根据商品等情况决定是否达成回收的双方约定，双方权利义务受《中华人民共和国民法典》之约束。用户申请回收，平台将根据补给箱实际支付价格打折购买，视具体情况而定，最低折扣为补给箱实际支付价格的60%，最高兑换价为补给箱实际支付价格的100%。商品回收后，平台将回收的价款以蒸汽币(平台专有虛拟币)的形式充值至用户在平台的专有账户蒸汽币余额中，货币(特指人民币)与蒸汽币的兑换比率为1: 1，蒸汽币余额可在平台直接支付完成在线交易。用户知晓并同意，商品回收或者拆封后不可撤销，请用户谨慎操作。</p>
    <p>2、因商品的特殊性质，支持回收的补给箱商品将不支持合成升级玩法。</p>
    <p>3、在购买补给箱后，用户应当在15天内选择拆封或回收。若用户经平台通知，购买后15天内仍未回收且未拆封，则视为用户同意商品可回收给平台，平台有权按不低于补给箱实际支付价格的60%，最高回收价为补给箱实际支付价格的100%进行回收，且将回收的价款以蒸汽币的形式充值至用户在平台的账户蒸汽币余额中；对于符合该条件的商品，平台在实际进行回收前的7天内，将以短信、APP通知或站内信之一的方式告知用户，便于用户在回收前提前处理商品；符合条件但未进行通知的，则视为平台未开始对该商品发起回收处理。</p>
    <p>4、商品回收的，价款按以下方式处理：</p>
    <p>4.1用户使用货币购买补给箱时，以用户在购买补给箱时实际支付的货币为基数，最低折扣为补给箱实际支付价格的60%，平台将回收款项以蒸汽币(平台专有虚拟币)的形式充值至用户在平台的账户蒸汽币余额中；</p>
    <p>4.2用户使用蒸汽币购买补给箱时，以用户在购买补给箱时实际消耗的蒸汽币数量为基数，最低折扣为购买商品实际消耗蒸汽币数的60%，平台回收后，回收蒸汽充值至用户账户蒸汽币余额中。</p>
    <p>5、用户可在“补给明细”和“钱包-资金流水”中查看回收的情况。</p>
    <p>6、用户在购买补给箱时，若使用任何形式的优惠券/抵用券/商品券/红包等的，商品回收时，优惠券/抵用券/商品券/红包不予返还，退款金额/蒸汽币数量按照不低于用户购买补给箱时实际支付的金额/实际回收的蒸汽币数量为基数的60%折算应退还金额/商城内货币。</p>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
 
  },
})
export default class Rule extends Vue {
}
</script>

<style lang="scss" scoped>
.rule{
    padding: 0 12px;
}
</style>
